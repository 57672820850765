<script setup lang="ts">
import { Card } from '#components'
import type { SeriesCollection } from '~/utils/types'

type SeriesReversed = NonNullable<
	Required<SeriesCollection['seriesReversed'][0]>
>

type SeriesReversedPublication = Extract<
	SeriesReversed,
	{
		__typename: `publicaties_${string}`
	}
>

const props = defineProps<{
	card: SeriesReversedPublication
	index: number
	selectedCardIndex: number
	numCards: number
}>()

const emit = defineEmits<{
	(e: 'select', index: number): void
}>()

const MAX_ANGLE = 65
const MIN_SCALE = 0.8

const parent = useParentElement()
const parentBounds = useElementBounding(parent)

const el = ref<HTMLElement>()
const zIndex = ref(1)
const cardRef = ref<InstanceType<typeof Card>>()
const xPosition = computed(() => {
	const center = parentBounds.width.value / 2
	const startX = center - (props.numCards * 100) / 2
	return startX + props.index * 100
})

const value = reactive({
	left: 0,
	rotateY: 0,
	scale: 1,
	offsetMarker: 70,
})
const { set } = useSpring(value, {
	damping: 45,
	stiffness: 400,
})

const onSelectedIndexChange = (newIndex: number) => {
	const indexDiff = Math.abs(props.index - newIndex)
	if (newIndex === props.index) {
		set({
			rotateY: 0,
			scale: 1,
			left: xPosition.value,
			offsetMarker: 0,
		})
		zIndex.value = props.numCards
	} else {
		const dir = props.index > newIndex ? -1 : 1
		set({
			rotateY: MAX_ANGLE * dir,
			scale: MIN_SCALE,
			left: xPosition.value + 120 * -dir,
			offsetMarker: 70,
		})
		zIndex.value = props.numCards - indexDiff
		if (cardRef.value) cardRef.value.showCardFront()
	}
}

watch(() => props.selectedCardIndex, onSelectedIndexChange)

onMounted(() => {
	onSelectedIndexChange(props.selectedCardIndex)
})

const onMouseEnter = () => {
	emit('select', props.index)
}

const { name, params } = useRoute()
const currentSlug = name === 'publicaties-slug' ? params.slug : undefined
</script>

<template>
	<div
		ref="el"
		class="card-container"
		:class="{
			['current-publication']: currentSlug === props.card.slug,
		}"
		:style="{
			left: `${value.left}px`,
			transform: `translateX(-50%) rotateY(${value.rotateY}deg)`,
			zIndex: zIndex,
		}"
		@mouseenter="onMouseEnter"
	>
		<img
			v-if="currentSlug === props.card.slug"
			:style="{
				transform: `scale(${value.scale}) translateX(-50%) translateY(${value.offsetMarker}px)`,
			}"
			class="absolute left-1/2 w-[18px] h-[25px] top-[-50px]"
			alt="Huidige publicatie"
			src="@/assets/icons/current-location.svg?inline"
		/>

		<Card
			ref="cardRef"
			:publication="card"
			:class="'cover-card'"
			:style="{
				transform: `scale(${value.scale})`,
			}"
			:on-focus="onMouseEnter"
		/>
	</div>
</template>

<style scoped lang="postcss">
.card-container {
	@apply absolute inline-flex justify-center whitespace-normal text-left overflow-visible;
}
</style>
