<script setup lang="ts">
import type { SeriesCollection } from '~/utils/types'

type SeriesReversed = NonNullable<
	Required<SeriesCollection['seriesToPublication'][0]>
>
type SeriesReversedPublications = Array<
	Extract<
		SeriesReversed,
		{
			__typename: `publicaties_${string}`
		}
	>
>

const props = defineProps<{
	publications: SeriesReversedPublications
}>()
const isOnThemePage = computed(() => {
	const { name } = useRoute()
	return name === 'themas-slug'
})

const initIndex = props.publications.findIndex(
	(c) => c.slug === useRoute().params.slug,
)
const state = reactive({
	cards: props.publications,
	currentIndex: initIndex >= 0 ? initIndex : 0,
})

const onCardSelect = (index: number) => {
	state.currentIndex = index
}

import { CollectionSeriesCover } from '#components'
const covers = ref<InstanceType<typeof CollectionSeriesCover>[]>([])
const containerEl = ref<HTMLDivElement>()

const target = reactive({ perspectiveOrigin: 0, ml: 0, mr: 0 })
const { set, values, stop } = useSpring(target, {
	damping: 50,
	stiffness: 220,
})

const wrapperWidth = computed(() => 500 + (props.publications.length - 1) * 95)
const { width: windowWidth } = useWindowSize()

const perspective = computed(() => {
	return isOnThemePage.value
		? props.publications.length * 200
		: wrapperWidth.value
})

watchImmediate(
	() => state.currentIndex,
	() => {
		if (!isOnThemePage.value) return
		// translate container to make up for margin caused by perspective
		let min = -30
		if (props.publications.length > 5)
			min = -50 + props.publications.length * 3

		const { value: offsetX } = useProjection(
			state.currentIndex,
			[0, props.publications.length - 1],
			[min, 80],
		)

		set({
			translateX: offsetX,
			perspectiveOrigin: state.currentIndex * 120,
		})
	},
)

const mouseX = ref(0)

const onMouseMove = (e: MouseEvent) => {
	mouseX.value = e.clientX
}

watchImmediate(
	() => [
		state.currentIndex,
		windowWidth.value,
		wrapperWidth.value,
		mouseX.value,
	],
	([index, windowWidth, wrapperWidth, mouseX]) => {
		if (isOnThemePage.value) return
		const margin = windowWidth * 0.01
		const p = mouseX / windowWidth
		const diff = wrapperWidth + margin - windowWidth
		const offsetX = p * diff
		set({
			translateX: diff > 0 ? -offsetX + margin / 2 : margin - diff / 2,
			perspectiveOrigin:
				state.currentIndex * (wrapperWidth / props.publications.length),
		})
	},
)
</script>

<template>
	<div class="cover-flow-wrapper" @mousemove="onMouseMove">
		<div
			class="cover-cards"
			ref="containerEl"
			:style="{
				perspectiveOrigin: `${values.perspectiveOrigin}px 50%`,
				perspective: `${Math.max(perspective, 750)}px`,
				transformStyle: 'preserve-3d',
				transform: `translateX(${values.translateX ?? '0'}px)`,
				width: `${wrapperWidth}px`,
			}"
		>
			<CollectionSeriesCover
				ref="covers"
				v-for="(card, index) in publications"
				:card="card"
				:index="index"
				:selectedCardIndex="state.currentIndex"
				:numCards="publications.length"
				@select="onCardSelect"
			/>
		</div>
	</div>
</template>

<style scoped lang="postcss">
.cover-flow-wrapper {
	@apply max-sm:mt-8;
}

.cover-cards {
	@apply w-full h-[410px] whitespace-nowrap mt-14 -mb-6;
}
</style>
