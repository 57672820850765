<script setup lang="ts">
import type SwiperType from 'swiper'
import { EffectCoverflow, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { SeriesCollection } from '~/utils/types'

// Import Swiper styles
import { Card } from '#components'

import 'swiper/css'
import 'swiper/css/effect-coverflow'

type SeriesReversed = NonNullable<
	Required<SeriesCollection['seriesReversed'][0]>
>
type SeriesReversedPublications = Array<
	Extract<
		SeriesReversed,
		{
			__typename: `publicaties_${string}`
		}
	>
>

const props = defineProps<{
	publications: SeriesReversedPublications
}>()

const swiperSlides = ref<InstanceType<typeof Card>[]>([])
let prevSlideIndex = props.publications.findIndex(
	(c) => c.slug === useRoute().params.slug,
)

const modules = [EffectCoverflow, Pagination]
</script>

<template>
	<div class="series-collection">
		<ClientOnly>
			<swiper
				class="cards"
				:effect="'coverflow'"
				:grabCursor="true"
				:centeredSlides="true"
				:slidesPerView="'auto'"
				:initialSlide="prevSlideIndex"
				:coverflowEffect="{
					rotate: 70, // TODO: make dynamic
					stretch: 80,
					depth: 100,
					modifier: 1,
					slideShadows: false,
				}"
				:modules="modules"
				@slideChange="
					(swiper: SwiperType) => {
						const prev = swiperSlides[prevSlideIndex]
						if (prev) prev.showCardFront()
						prevSlideIndex = swiper.activeIndex
					}
				"
			>
				<swiper-slide
					v-for="(card, index) in publications"
					class="card"
				>
					<img
						v-if="useRoute().params.slug === card.slug"
						class="absolute left-1/2 w-[18px] h-[25px] top-[-36px]"
						src="@/assets/icons/current-location.svg?inline"
						alt="Indicatie van huidige publicatie"
					/>
					<Card
						ref="swiperSlides"
						:index="index"
						:publication="card"
					/>
				</swiper-slide>

				<div class="mt-6">
					<MiscSwiperPagination class="!my-0" />
				</div>
			</swiper>
		</ClientOnly>
	</div>
</template>

<style scoped lang="postcss">
.series-collection {
	@apply mb-0 mt-10;

	.theme__series & {
		@apply max-sm:mt-0;
	}
}
.cards {
	@apply w-full !overflow-y-visible pt-8;

	.theme__series & {
		@apply max-sm:pt-0;
	}
}
.card {
	@apply !w-auto;
}
</style>
